<template>
  <div>
    <v-autocomplete
      item-value="id"
      v-model="model"
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      :filter="filter"
      :label="label"
      :chips="chips"
      :deletableChips="deletableChips"
      :outlined="outlined"
      :dense="dense"
      :rules="rules"
      :multiple="multiple"
      :auto-select-first="autoSelectFirst"
      hide-details
      hide-selected
    >
      <template v-slot:no-data>
        <p class="ma-4" v-if="isLoading">Carregando...</p>
        <p class="ma-4" v-else-if="!search">Digite para buscar</p>
        <p class="ma-4" v-else-if="search.length < 4">Digite pelo menos 4 caracteres</p>
        <p class="ma-4" v-else>Nenhum resultado encontrado</p>
      </template>
      <template v-slot:selection="data">
        <v-chip
          class="mt-1 mb-1"
          v-if="chips"
          :small="smallChips"
          :close="deletableChips"
          close-icon="mdi-close"
          @click:close="removeItem(data.item)"
        >
          {{ data.item.Address | formatAddress }}
        </v-chip>
        <span v-else>
          {{ data.item.Address | formatAddress }}
        </span>
      </template>
      <template v-slot:item="data">
        <span>
          <v-chip :color="statusChip[data.item.status].color" :disabled="data.item.disabled" x-small outlined label>
            {{ statusChip[data.item.status].text }}
          </v-chip>
          {{ data.item.Address | formatAddress }}
        </span>
      </template>
      <template v-slot:append-item>
        <v-divider class="mb-2"></v-divider>
        <v-row class="mt-2 mb-0">
          <v-col cols="12" class="text-center">
            <v-btn small color="primary" @click="$emit('immobile-modal')">Cadastrar Imóvel</v-btn>
          </v-col>
        </v-row>
      </template>
    </v-autocomplete>
    <immobile-modal @success="selectImmobile" />
  </div>
</template>

<script>
import ImmobileModal from '@/components/modals/ImmobileModal.vue'
import IMMOBiLE from '@/services/immobile'

export default {
  components: { ImmobileModal },
  props: {
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String | Number | Array,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
    },
  },
  data() {
    return {
      model: this.multiple ? [this.value] : `${this.value}`,
      search: null,
      isLoading: false,
      items: [],
      timeout: null,
      statusChip: {
        rented: { text: 'Alugado', color: 'success' },
        reserved: { text: 'Reservado', color: 'warning' },
        inactive: { text: 'Inativo', color: 'error' },
        available: { text: 'Disponível', color: 'accent' },
      },
    }
  },
  methods: {
    removeItem(item) {
      this.model = this.model.filter(i => i !== item.id)
    },
    filter(obj, queryText) {
      const ltQueryText = queryText.toLowerCase()
      const address = obj.Address
      return (
        obj.id == queryText ||
        address.street.toLowerCase().includes(ltQueryText) ||
        address.district.toLowerCase().includes(ltQueryText) ||
        address.city.toLowerCase().includes(ltQueryText) ||
        address.state.toLowerCase().includes(ltQueryText) ||
        address.country.toLowerCase().includes(ltQueryText) ||
        address.zipCode.toLowerCase().includes(ltQueryText)
      )
    },
    selectImmobile(client) {
      if (this.multiple) this.model = [...this.model, client.id]
      else this.model = client.id

      this.$emit('input', this.modal)
    },
    async s(val) {
      try {
        if (!val || val.length < 4 || this.isLoading) return

        this.isLoading = true
        const { immobiles } = await IMMOBiLE.search(val)
        immobiles.forEach(immobile => {
          if (!this.items.find(item => item.id === immobile.id))
            this.items.push({ ...immobile, disabled: immobile.status !== 'available' })
        })
        if (this.selected) {
          this.selected = this.items.find(i => i.id == this.selected.id)
        }
      } catch (e) {
        this.$toast.fire({
          icon: 'error',
          title: e.message,
        })
      } finally {
        this.isLoading = false
      }
    },
  },
  filters: {
    formatAddress(address) {
      return `${address.street}, ${address.number} - ${address.district} - ${address.city} - ${address.state} - ${address.country}`
    },
  },
  watch: {
    value: {
      async handler(value) {
        this.model = value
        if (value) await this.s(value)
      },
      immediate: true,
    },
    model(value) {
      if (!value) this.$emit('input', null)
      this.$emit('input', value)
      this.search = null
    },
    async search(val) {
      if (!val || val.length < 4) return
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.s(val)
      }, 500)
    },
  },
  async mounted() {
    const value = this.value
    if (!value) return
    this.model = value
    await this.s(value, false)
  },
}
</script>

<style></style>
